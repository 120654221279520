import {
    HttpUtil
} from '../assets/util/http';

export const qiniuUploadMixin = {
    data() {
        return {
            qnUploadData: {
                token: '',
                key: null,
                cdn: '',
            },
        };
    },
    async created() {
        let json = await HttpUtil.get('/qiniu/token');
        this.qnUploadData.token = json.token;
        this.qnUploadData.cdn = json.cdn;
    },
    methods: {
        joinCDN(url) {
            return `${this.qnUploadData.cdn}/${url}`;
        },

        safeBase64(url) {
            url = btoa(url);
            url = url.split('+').join('-');
            url = url.split('/').join('_');
            return url;
        },
    },
};